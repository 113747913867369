<template>
  <div v-bind:id="`realEstate-picker-${uniqueId}`" class="d-inline-block w-100 realEstate-picker">
    <!--unique input-->
    <div dir="ltr" class="v-select w-100 vs--single vs--searchable" v-bind:class="{'small':small}"
         v-if="multiple !== true">
      <div class="vs__dropdown-toggle" @click="toggleModal">
        <!--selected-->
        <div class="vs__selected-options">
          <span class="vs__selected invisible" v-if="uniqueRealEstateName == null">none</span>
          <span class="vs__selected" v-bind:class="{'text-warning': uniqueRealEstateName.valid !== true}"
                v-else>{{ uniqueRealEstateName.label }}</span>
        </div>
        <!--actions-->
        <div class="vs__actions cursor-pointer">
          <button type="button" class="vs__clear" v-if="clearable === true && uniqueRealEstateName != null"
                  @click="clear">
            <feather-icon icon="XIcon"/>
          </button>
          <feather-icon icon="HomeIcon" @click="()=>toggleModal(true)"/>
        </div>
      </div>
    </div>
    <!--Multiple input-->
    <div dir="ltr" class="v-select w-100 vs--searchable" v-else>
      <div role="combobox" class="vs__dropdown-toggle" @click="onComboboxClick">
        <!-- selected-->
        <div class="vs__selected-options">
          <!--On empty-->
          <span class="vs__selected invisible" v-if="multipleRealEstatesNames.length === 0">empty</span>
          <!--Selected badges-->
          <span class="vs__selected" v-bind:class="{'bg-warning':realEstate.valid !== true}"
                v-for="realEstate in multipleRealEstatesNames" v-bind:key="realEstate.id">
                    {{ realEstate.label }}
                    <button type="button" class="vs__deselect"
                            v-if="clearable === true || multipleRealEstatesNames.length > 1"
                            @click="()=>removeRealEstate(realEstate.id)">
                       <feather-icon icon="XIcon"/>
                    </button>
                </span>
        </div>
        <!--Actions-->
        <div class="vs__actions cursor-pointer" @click="()=>toggleModal(true)">
          <feather-icon icon="HomeIcon"/>
        </div>
      </div>
    </div>
    <!--Modal table-->
    <b-modal no-fade v-model="modal.active" size="xl" :ref="modalId" :id="modalId" :hide-footer="true" centered
             @show="refresh">
      <div>
        <div class="mb-1">
          <b-input-group class="w-100">
            <b-form-input v-model="modal.filter.like.search" debounce="500"/>
            <b-input-group-append>
              <b-button v-b-toggle.advancedFilter variant="primary">
                <i class="fas fa-caret-down"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-collapse id="advancedFilter">
            <b-row>
              <!-- Id -->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Id") }}</label>
                <b-form-input
                    v-model="modal.filter.like.id"
                    class="d-inline-block mr-1" debounce="500"
                />
              </b-col>
              <!--Address-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Address") }}</label>
                <b-form-input
                    v-model="modal.filter.like.address"
                    class="d-inline-block mr-1" debounce="500"
                />
              </b-col>
              <!-- House number-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("House number") }}</label>
                <b-form-input
                    v-model="modal.filter.like.house"
                    class="d-inline-block mr-1" debounce="500"
                />
              </b-col>
              <!-- Zip-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Zip") }}</label>
                <b-form-input
                    v-model="modal.filter.like.zip"
                    class="d-inline-block mr-1" debounce="500"
                />
              </b-col>
              <!--City-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("City") }}</label>
                <b-form-input
                    v-model="modal.filter.like.city"
                    class="d-inline-block mr-1" debounce="500"
                />
              </b-col>
              <!-- Rooms-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Rooms") }}</label>
                <b-form-input
                    v-model="modal.filter.eq.realEstateArea.rooms"
                    class="d-inline-block mr-1" debounce="500"
                />
              </b-col>
              <!-- Living space-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Living space") }}</label>
                <b-form-input
                    v-model="modal.filter.eq.realEstateArea.livingSpace"
                    class="d-inline-block mr-1" debounce="500"
                />
              </b-col>
              <!-- Total space-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Total space") }}</label>
                <b-form-input
                    v-model="modal.filter.eq.realEstateArea.totalSpace"
                    class="d-inline-block mr-1" debounce="500"
                />
              </b-col>

              <!--Buy/Rent-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <b-form-group :label="msg('Buy/Rent')" label-for="realEstate-branch">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      v-model="modal.filter.eq.marketingType"
                      :options="[{value:{purchase:false,rent:true},label:msg('Rent')},{value:{purchase:true,rent:false},label:msg('Purchase')}]"
                      class="w-100"
                      label="label"
                      :reduce="(branch) => branch.value"
                  />
                </b-form-group>
              </b-col>
              <!--Usage-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <b-form-group :label="msg('Usage')" label-for="realEstate-branch">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      v-model="modal.filter.eq.usageType"
                      :options="[
                  {value:{business:false,investment:false,living:true,waz:false},label:msg('Living')},
                  {value:{business:true,investment:false,living:false,waz:false},label:msg('Business')},
                  {value:{business:false,investment:true,living:false,waz:false},label:msg('Investment')}]"
                      class="w-100"
                      label="label"
                      :reduce="(branch) => branch.value"
                  />
                </b-form-group>
              </b-col>
              <!--Nuki status-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <b-form-group :label="msg('Nuki status')" label-for="realEstate-branch">
                  <v-select
                      multiple
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      v-model="modal.filter.in.nukiStatus"
                      :options="[
                      {value:null,label:msg('NODEVICE')},
                      {value:'INSTALLED',label:msg('INSTALLED')},
                      {value:'TOINSTALL',label:msg('TOINSTALL')},
                      {value:'FAULT',label:msg('FAULT')},
                  ]"
                      class="w-100"
                      label="label"
                      :reduce="(branch) => branch.value"

                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-collapse>
        </div>
        <b-table hover small class="real-estate-table-picker" v-bind:items="realEstateTableData" v-bind:fields="fields"
                 @row-clicked="onRowClicked">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <template #cell(address)="data">
            <div>
              <div style="white-space: nowrap">

                <span v-if="data.item.address" class="d-inline-block"
                      style="margin-left: 5px">{{ data.item.address }},</span>
                <span v-if="data.item.house" class="d-inline-block"
                      style="margin-left: 5px">{{ data.item.house }},</span>
                <span v-if="data.item.apt" class="d-inline-block"
                      style="margin-left: 5px">Apt. {{ data.item.apt }},</span>
                <span v-if="data.item.city" class="d-inline-block" style="margin-left: 5px">{{ data.item.city }}</span>
                <span> {{ msg('Zip') }}: </span>{{ data.item.zip }}
              </div>
              <div style="margin-left: 5px">

              </div>
            </div>
          </template>
          <!--          <template #cell(attachments)="data">
                      <b-img
                          v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,
                                          src:(data.value != null && data.value[0] != null ? mergeSrcUrl(getRealEstatesWebSite,data.value[0]) : null)  }"
                          blank-color="#475F7B"
                          rounded
                          alt="Rounded image"
                          class="d-inline-block m-1"
                      />
                    </template>-->
          <template #cell(realEstatePrices)="data">
            <div>
              {{ getPrices(data) }}
            </div>
          </template>
          <!--Cell status-->
          <template #cell(status)="data" class="p-0">
            <div>
              <b-badge style="margin-right: 10px" :variant="statusVariants(data.value)">Status: {{
                  data.value
                }}
              </b-badge>

              <b-badge style="margin-right: 10px" :variant="data.item.nukiStatus!=null?'primary':'danger'">Nuki:
                {{ data.item.nukiStatus != null ? msg(data.item.nukiStatus) : "Kein Gerät" }}
              </b-badge>

              <b-badge
                  :variant="data.item.advertise !=null && data.item.advertise===true ?'success':'danger'">
                Inserat: {{ getFeaturedStatus(data.item) }}
              </b-badge>
            </div>


          </template>
          <template #cell(userIds)="data">
            <div v-for="userId in data.value" v-bind:key="userId">
              <b-badge variant="info">{{ getUserFullName(userId) }}</b-badge>
            </div>
          </template>
        </b-table>
        <div class="mt-1">
          <b-pagination v-model="pagination.page" v-bind:total-rows="pagination.total"
                        v-bind:per-page="pagination.amountPerPage" align="center"
                        @input="refresh"></b-pagination>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "realEstatePicker",
  data: () => ({
    uniqueId: 0,
    modal: {
      active: false,
      realEstates: [],
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}},
        lte: {},
        gte: {},
        in: {}
      },
    },
    pagination: {
      amountPerPage: 10,
      total: 1,
      number: 1,
    },
    sort: {
      col: "Creationtime",
      order: "desc"
    },
    selected: {
      unique: null,
      multiple: null,
      data: [],
      loading: false,
      error: false,
    }
  }),
  props: {
    value: {
      required: true
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    small: {
      type: Boolean,
      default: () => false
    },
    noBuildingYet: {
      type: Boolean,
      default: () => false
    },
    buildings: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value: {
      handler() {
        this.selected.multiple = null;
        this.selected.unique = null;
        if (this.multiple !== true) {
          this.selected.unique = this.value;
        } else {
          if (this.value != null && !Array.isArray(this.value)) {
            this.$emit('input', (!isNaN(this.value) ? [this.value] : []))
            return;
          }
          this.selected.multiple = this.value;
        }
        this.refreshSelected();
      },
      immediate: true
    },
    "modal.filter": {
      handler() {
        this.refresh();
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid
  },
  computed: {
    ...mapGetters('data', ['getRealEstatesWebSite']),
    ...mapGetters('realEstate', ['allStatus', 'statusVariants', 'getPrices', 'getFeaturedStatus', 'getFullAddress']),
    ...mapGetters('data', ['getUserFullName']),
    fields() {
      let $this = this;
      return [

        /*{
          key: 'attachments',
          label: $this.msg('Main image'),
          sortable: true,
          active: true,
        },*/
        /* {
           key: 'id',
           label: $this.msg('id'),
           sortable: true,
           active: true,
         },*/
        {
          key: 'name',
          label: $this.msg('Name'),
          sortable: true,
          active: true,
        },
        {
          key: 'address',
          label: $this.msg('Address'),
          sortable: true,
          active: true,
        },
        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true,
          active: true,
        },
        {
          key: 'userIds',
          label: $this.msg('Agent/s'),
          sortable: true,
          active: true,
        },
        {
          key: 'realEstatePrices',
          label: $this.msg('Price'),
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.totalSpace',
          label: $this.msg('Total space'),
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.rooms',
          label: $this.msg('Rooms'),
          sortable: true,
          active: true,
        }
      ]
    },
    realEstateTableData() {
      let realEstatesMap = (this.modal.realEstates || []).map(rs => ({...rs})).toObject("id");
      let selectedToMerge = [];
      for (let selectedRealEstate of this.selected.data) {
        if (realEstatesMap[selectedRealEstate.id] == null) {
          selectedRealEstate["_rowVariant"] = 'success';
          selectedToMerge.push(selectedRealEstate);
        } else {
          realEstatesMap[selectedRealEstate.id]["_rowVariant"] = 'success';
        }
      }
      return [...selectedToMerge, ...Object.values(realEstatesMap)]
    },
    modalId() {
      return `realEstate-modal-picker-${this.uniqueId}`
    },
    uniqueRealEstateName() {
      if (this.value == null || Array.isArray(this.value) || this.value === "")
        return null;
      if (!Array.isArray(this.selected.data) || this.selected.data.length === 0)
        return {label: `${this.selected.unique} - ${this.msg('Unknown')}`, valid: false};
      if (this.selected.data[0].id !== this.selected.unique) {
        return {label: `${this.selected.unique} - ${this.msg('Unknown')}`, valid: false};
      }
      return {
        label: `${this.value.substr(this.value.length - 6)} - ${this.getFullAddress(this.selected.data[0])}`,
        valid: true
      };
    },
    multipleRealEstatesNames() {
      if (!Array.isArray(this.value) || this.value.length === 0)
        return [];
      let currentData = (this.selected.data || []).toObject("id");
      return this.value.map(realEstateId => {
        let realEstate = currentData[realEstateId];
        return {
          label: `${realEstateId.substr(realEstateId.length - 6)} - ${realEstate != null ? this.getFullAddress(realEstate) : this.msg('Unknown')}`,
          id: realEstateId,
          valid: realEstate != null,
        }
      });
    }
  },
  methods: {
    ...mapActions('realEstate', ['getAllRealEstates']),
    refreshSelected() {
      let $this = this;
      this.selected.loading = true;
      if (this.value == null || (Array.isArray(this.value) && this.value.length === 0)) {
        this.clear(true);
        this.selected.loading = false;
        return;
      }
      let realEstateIds = Array.isArray(this.value) ? this.value : [this.value];
      if (!this.updateIsRequired(realEstateIds)) {
        this.selected.loading = false;
        return;
      }
      let filter = {in: {id: realEstateIds}};
      this.getAllRealEstates({filter})
          .then(data => {
            $this.selected.data = data['RealEstates'];
          }, () => $this.selected.error = true).finally(() => $this.selected.loading = false);
    },
    updateIsRequired(realEstateIds) {
      if (this.selected.data.length !== realEstateIds.length) {
        return true;
      }
      let currentData = (this.selected.data || []).toObject("id");
      for (let realEstateId of realEstateIds) {
        if (currentData[realEstateId] == null) {
          return true;
        }
      }
      return false;
    },
    clear(force) {
      if (this.clearable !== true && force !== true)
        return;
      this.selected.multiple = null;
      this.selected.unique = null;
      this.selected.data = [];
      this.$emit('input', null)
    },
    removeRealEstate(realEstateId) {
      let currentValue = this.value != null ? [...this.value] : [];
      const index = currentValue.findIndex(rId => rId === realEstateId);
      if (index < 0) {
        return false
      }
      currentValue.splice(index, 1);
      this.selected.data = this.selected.data.filter(realEstate => realEstate.id !== realEstateId);
      this.$emit('input', currentValue);
      return true;
    },

    refresh() {
      let $this = this;
      let filter = {...$this.modal.filter};
      if (Array.isArray(this.buildings) && this.buildings.length > 0) {
        filter.in.buildingId = this.buildings;
      }
      this.getAllRealEstates({
        sortby: $this.sort.order,
        sortcolumn: $this.sort.col,
        page: $this.pagination.page,
        amount: $this.pagination.amountPerPage,
        filter
      }).then(this.onRealEstatesReceived);
    },
    onRealEstatesReceived(data) {
      if (this.noBuildingYet)
        this.modal.realEstates = data['RealEstates'].filter(rs => rs.buildingId == null);
      else
        this.modal.realEstates = data['RealEstates'];
      this.pagination.total = data['Total'];
      this.pagination.page = data['Page'];
      this.$root.$emit('bv::refresh::table')
    },
    toggleModal(status) {
      this.modal.active = status != null ? status : !this.modal.active;
    },
    onComboboxClick(ev) {
      console.log("onComboboxClick", {ev, srcElement: ev.srcElement})
      if (ev.srcElement != null && ev.srcElement.classList.contains("vs__selected-options")) {
        this.toggleModal(true);
      }
    },
    onRowClicked(realEstate) {
      if (this.multiple !== true) {
        this.selected.data = [realEstate];
        this.$emit('input', realEstate.id);
        this.modal.active = false;
        return
      }
      if (this.value == null) {
        this.selected.data = [realEstate];
        this.$emit('input', [realEstate.id]);
        return;
      }
      if (this.removeRealEstate(realEstate.id) === true) {
        return;
      }

      this.selected.data.push(realEstate);
      this.$emit('input', [...this.value, realEstate.id]);
    }
  }
}
</script>

<style lang="scss">
.realEstate-picker {
  .input-group > .realEstate-picker-unique.input-group-append {
    > .btn.realEstate-picker-clear {
      padding-right: 4px;
      padding-left: 4px;
      border: none !important;
    }

    > .btn.realEstate-picker-add {
      border: none !important;
    }

  }
}

.real-estate-table-picker.table.table-sm td {
  padding: 0 0;
}
</style>
