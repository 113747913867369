var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realEstatePanel"},[(_vm.loaded)?_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"refFormObserver"},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"PackageIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" "+_vm._s(_vm.msg('Generate contract'))+" ")])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"2","md":"2","lg":"2"}},[_c('b-form-group',{staticClass:"orange-color",attrs:{"label":_vm.msg('Export contract to channels'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Hostaway'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"disabled":"","name":"check-button","switch":""},model:{value:(_vm.contract.hostawayApi),callback:function ($$v) {_vm.$set(_vm.contract, "hostawayApi", $$v)},expression:"contract.hostawayApi"}},[_vm._v(" "+_vm._s(_vm.contract.hostawayApi ? _vm.msg('Yes') : _vm.msg('No'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1616458844)})],1)],1),_c('b-col',{attrs:{"cols":"2","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('newclients'),"label-for":"realEstate-namfsde"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-busfsgfsgtton","switch":""},model:{value:(_vm.contract.isNewClient),callback:function ($$v) {_vm.$set(_vm.contract, "isNewClient", $$v)},expression:"contract.isNewClient"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4294258830)})],1)],1),_c('b-col',{attrs:{"cols":"2","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Pricelabs price'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('pricelabs'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.isPricelabs),callback:function ($$v) {_vm.isPricelabs=$$v},expression:"isPricelabs"}},[_vm._v(" "+_vm._s(_vm.isPricelabs ? _vm.msg('Yes') : _vm.msg('No'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,571612590)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Paid'),"label-for":"realEstate-name"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.contract.paid),callback:function ($$v) {_vm.$set(_vm.contract, "paid", $$v)},expression:"contract.paid"}},[_vm._v(" "+_vm._s(_vm.contract.paid ? _vm.msg('Yes') : _vm.msg('No'))+" ")])],1)],1),_c('b-col',{attrs:{"cols":"3"}}),(!_vm.contract.isNewClient)?[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Client Id'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('client-picker',{model:{value:(_vm.contract.clientId),callback:function ($$v) {_vm.$set(_vm.contract, "clientId", $$v)},expression:"contract.clientId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.msg(errors[0])))])]}}],null,false,2081052943)})],1)],1)]:[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Firstname'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contract.client.firstname),callback:function ($$v) {_vm.$set(_vm.contract.client, "firstname", $$v)},expression:"contract.client.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3513616825)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Lastname'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contract.client.lastname),callback:function ($$v) {_vm.$set(_vm.contract.client, "lastname", $$v)},expression:"contract.client.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3613390665)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Phone'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contract.client.phone),callback:function ($$v) {_vm.$set(_vm.contract.client, "phone", $$v)},expression:"contract.client.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2478056856)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Email'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contract.client.email),callback:function ($$v) {_vm.$set(_vm.contract.client, "email", $$v)},expression:"contract.client.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3198821512)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Address'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contract.client.address.street),callback:function ($$v) {_vm.$set(_vm.contract.client.address, "street", $$v)},expression:"contract.client.address.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1146047421)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Zip'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Zip'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contract.client.address.zip),callback:function ($$v) {_vm.$set(_vm.contract.client.address, "zip", $$v)},expression:"contract.client.address.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3252019487)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('City'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('City'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contract.client.address.city),callback:function ($$v) {_vm.$set(_vm.contract.client.address, "city", $$v)},expression:"contract.client.address.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,580598107)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Country'),"label-for":"client-country"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":_vm.allCountries,"label":"name","reduce":function (lang) { return lang.iso; },"autocomplete":"on"},model:{value:(_vm.contract.client.address.country),callback:function ($$v) {_vm.$set(_vm.contract.client.address, "country", $$v)},expression:"contract.client.address.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1649887438)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Number of guests'),"label-for":"realEstate-type"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Number of guests'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-sell-price","type":"number"},model:{value:(_vm.contract.numberOfGuests),callback:function ($$v) {_vm.$set(_vm.contract, "numberOfGuests", $$v)},expression:"contract.numberOfGuests"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,287148835)})],1)],1)]],2),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Real estates'),"label-for":"realEstate-name"}},[_c('real-estate-picker',{attrs:{"multiple":""},model:{value:(_vm.contract.propertyIds),callback:function ($$v) {_vm.$set(_vm.contract, "propertyIds", $$v)},expression:"contract.propertyIds"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Start'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Start date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('calendar-picker',{model:{value:(_vm.contract.bnbStyleRent.start),callback:function ($$v) {_vm.$set(_vm.contract.bnbStyleRent, "start", $$v)},expression:"contract.bnbStyleRent.start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1378029170)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.msg('End'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('End date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('calendar-picker',{model:{value:(_vm.contract.bnbStyleRent.end),callback:function ($$v) {_vm.$set(_vm.contract.bnbStyleRent, "end", $$v)},expression:"contract.bnbStyleRent.end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3367644317)})],1)],1),_c('b-col',{staticStyle:{"margin-top":"5vh"},attrs:{"cols":"3","md":"3","lg":"3"}},[_c('b-button',{staticStyle:{"float":"right"},attrs:{"disabled":!_vm.isPricelabs || _vm.contract.propertyIds.length<1 || this.contract.bnbStyleRent.start == null || this.contract.bnbStyleRent.end == null,"variant":"danger","label-for":"realEstate-name"},on:{"click":_vm.calculatePricelabsPrice}},[_vm._v(" "+_vm._s(_vm.msg('Calculate Pricelabs price'))+" ")])],1)],1),(_vm.contract.propertyIds!=null && _vm.contract.propertyIds.length>0)?_c('b-row',{staticClass:"mt-2"},[(_vm.isPricelabs)?_vm._l((_vm.realEstatesIdAddressMap),function(address,id){return _c('b-col',{key:id,attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":((_vm.msg('Price for')) + " " + address),"label-for":("price-" + id)}},[_c('validation-provider',{attrs:{"name":((_vm.msg('Price for')) + " " + address),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":"","id":("realEstate-sell-price-" + id),"type":"number"},model:{value:(_vm.contract.prices[id]),callback:function ($$v) {_vm.$set(_vm.contract.prices, id, $$v)},expression:"contract.prices[id]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}):_vm._l((_vm.realEstatesIdAddressMap),function(address,id){return _c('b-col',{key:id,attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":((_vm.msg('Price for')) + " " + address),"label-for":("price-" + id)}},[_c('validation-provider',{attrs:{"name":((_vm.msg('Price for')) + " " + address),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("realEstate-sell-price-" + id),"type":"number","step":"any"},model:{value:(_vm.contract.prices[id]),callback:function ($$v) {_vm.$set(_vm.contract.prices, id, $$v)},expression:"contract.prices[id]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)})],2):_vm._e()],1)],1):(this.error != null)?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"fas fa-exclamation-triangle mr-3"}),_c('strong',[_vm._v(_vm._s(_vm.error))])])],1):_c('div',[_c('div',{staticStyle:{"min-height":"300px","text-align":"center"}},[_c('div',{staticStyle:{"margin-top":"20%"}},[_c('b-spinner',{attrs:{"label":"Spinning"}}),_vm._v(" "+_vm._s(_vm.msg('loading'))+" ")],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }