<template>
  <div v-bind:id="`client-picker-${uniqueId}`" class="d-inline-block w-100 client-picker">
    <!--unique input-->
    <div dir="ltr" class="v-select w-100 vs--single vs--searchable" v-if="multiple !== true">
      <div class="vs__dropdown-toggle">
        <!--selected-->
        <div class="vs__selected-options">
          <span class="vs__selected invisible" v-if="uniqueClientName == null">none</span>
          <span class="vs__selected" v-bind:class="{'text-warning': uniqueClientName.valid !== true}"
                v-else>{{ uniqueClientName.label }}</span>
        </div>
        <!--actions-->
        <div class="vs__actions cursor-pointer">
          <button type="button" class="vs__clear" v-if="clearable === true && uniqueClientName != null" @click="clear">
            <feather-icon icon="XIcon"/>
          </button>
          <feather-icon icon="UserPlusIcon" @click="()=>toggleModal(true)"/>
        </div>
      </div>
    </div>
    <!--Multiple input-->
    <div dir="ltr" class="v-select w-100 vs--searchable" v-else>
      <div role="combobox" class="vs__dropdown-toggle">
        <!-- selected-->
        <div class="vs__selected-options">
          <!--On empty-->
          <span class="vs__selected invisible" v-if="multipleClientsNames.length === 0">empty</span>
          <!--Selected badges-->
          <span class="vs__selected" v-bind:class="{'bg-warning':client.valid !== true}" v-for="client in multipleClientsNames" v-bind:key="client.id">
                    {{ client.label }}
                    <button type="button" class="vs__deselect"
                            v-if="clearable === true || multipleClientsNames.length > 1"
                            @click="()=>removeClient(client.id)">
                       <feather-icon icon="XIcon"/>
                    </button>
                </span>
        </div>
        <!--Actions-->
        <div class="vs__actions cursor-pointer" @click="()=>toggleModal(true)">
          <feather-icon icon="UserPlusIcon"/>
        </div>
      </div>
    </div>
    <!--Modal table-->
    <b-modal no-fade v-model="modal.active" size="xl" :ref="modalId" :id="modalId" :hide-footer="true" centered
             @show="refresh">
      <div>
        <div class="mb-1">
          <b-input-group class="w-100">
            <b-form-input v-model="modal.filter.search" debounce="500"/>
            <b-input-group-append>
              <b-button v-b-toggle.advancedFilter variant="primary">
                <i class="fas fa-caret-down"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-collapse id="advancedFilter">
            <b-row>
              <!-- Document -->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Document number") }}</label>
                <b-form-input
                    v-model="modal.filter.docNum"
                    debounce="500"
                    class="d-inline-block mr-1"
                />
              </b-col>
              <!-- First name-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("First name") }}</label>
                <b-form-input
                    v-model="modal.filter.firstname"
                    debounce="500"
                    class="d-inline-block mr-1"
                />
              </b-col>
              <!-- Last name-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Last name") }}</label>
                <b-form-input
                    v-model="modal.filter.lastname"
                    debounce="500"
                    class="d-inline-block mr-1"
                />
              </b-col>
              <!-- Phone-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Phone") }}</label>
                <b-form-input
                    v-model="modal.filter.phone"
                    debounce="500"
                    class="d-inline-block mr-1"
                />
              </b-col>
              <!-- Email-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Email") }}</label>
                <b-form-input
                    v-model="modal.filter.email"
                    debounce="500"
                    class="d-inline-block mr-1"
                />
              </b-col>
              <!--External ID-->
              <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>{{ msg("Username") }}</label>
                <b-form-input
                    v-model="modal.filter.externalid"
                    class="d-inline-block mr-1"
                />
              </b-col>
            </b-row>
          </b-collapse>
        </div>
        <b-table hover v-bind:items="clientTableData" v-bind:fields="fields" @row-clicked="onRowClicked"/>
        <div class="mt-1">
          <b-pagination v-model="pagination.number" :total-rows="pagination.total" v-bind:per-page="10" align="center"
                        @input="refresh"></b-pagination>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "clientPicker",
  data: () => ({
    uniqueId: 0,
    modal: {
      active: false,
      clients: [],
      filter: {
        search: null,
        active:1
      }
    },
    pagination: {
      amountPerPage: 10,
      total: 1,
      number: 1,
    },
    sort: {
      col: "Creationtime",
      order: "desc"
    },
    selected: {
      unique: null,
      multiple: null,
      data: [],
      loading: false,
      error: false,
    }
  }),
  props: {
    value: {
      required: true
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    isOwner: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.selected.multiple = null;
        this.selected.unique = null;
        if (this.multiple !== true) {
          this.selected.unique = this.value;
        } else {
          if (this.value != null && !Array.isArray(this.value)) {
            this.$emit('input', (!isNaN(this.value) ? [this.value] : []))
            return;
          }
          this.selected.multiple = this.value;
        }
        this.refreshSelected();
      },
      immediate: true
    },
    "modal.filter": {
      handler() {
        this.refresh();
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid
  },
  computed: {
    fields() {
      let $this = this;
      return [
        {
          key: 'id',
          label: $this.msg('Id'),
          sortable: false,
          active: true,
        },
        {
          key: 'creationTime',
          label: $this.msg('Creationtime'),
          sortable: false,
          active: true,
        },
        {
          key: 'birthday',
          label: $this.msg('Birthday'),
          sortable: false,
          active: true,
        },
        {
          key: 'clientFullname',
          label: $this.msg('Name'),
          sortable: false,
          active: true,
        },
        {
          key: 'phone',
          label: $this.msg('phone'),
          sortable: false,
          active: true,
        },
        {
          key: 'mobile',
          label: $this.msg('mobile'),
          sortable: false,
          active: true,
        },
        {
          key: 'email',
          label: $this.msg('email'),
          sortable: false,
          active: true,
        },]
    },
    clientTableData() {
      let clients = this.modal.clients || [];
      let currentClientIds = (this.selected.data || []).map(c => c.id);
      clients.forEach(client => client["_rowVariant"] = (currentClientIds.includes(client.id)) ? 'success' : null)
      return clients;
    },
    modalId() {
      return `client-modal-picker-${this.uniqueId}`
    },
    uniqueClientName() {
      if (this.value == null || Array.isArray(this.value) || this.value === "")
        return null;
      if (!Array.isArray(this.selected.data) || this.selected.data.length === 0)
        return {label: `${this.selected.unique} - ${this.msg('Unknown')}`, valid: false};
      if (this.selected.data[0].id !== this.selected.unique) {
        return {label: `${this.selected.unique} - ${this.msg('Unknown')}`, valid: false};
      }
      return {label: `${this.selected.data[0].id} - ${this.selected.data[0].clientFullname}`, valid: true};
    },
    multipleClientsNames() {
      if (!Array.isArray(this.value) || this.value.length === 0)
        return [];
      let currentData = (this.selected.data || []).toObject("id");
      return this.value.map(clientId => {
        let client = currentData[clientId];
        return {
          label: `${clientId} - ${client != null ? client.clientFullname : this.msg('Unknown')}`,
          id: clientId,
          valid: client != null,
        }
      });
    }
  },
  methods: {
    ...mapActions('clients', ['getClients']),
    refreshSelected() {
      let $this = this;
      this.selected.loading = true;
      if (this.value == null || (Array.isArray(this.value) && this.value.length === 0)) {
        this.clear(true);
        this.selected.loading = false;
        return;
      }
      let clientIds = Array.isArray(this.value) ? this.value : [this.value];
      this.getClients({filter: {ids: clientIds}})
          .then(data => {
            $this.selected.data = data['clients'];
          }, () => $this.selected.error = true).finally(() => $this.selected.loading = false);
    },
    clear(force) {
      if (this.clearable !== true && force !== true)
        return;
      this.selected.multiple = null;
      this.selected.unique = null;
      this.selected.data = [];
      this.$emit('input', null)
    },
    removeClient(clientId) {
      let currentValue = this.value != null ? [...this.value] : [];
      const index = currentValue.indexOf(clientId);
      if (index < 0) {
        return false
      }
      currentValue.splice(index, 1);
      this.$emit('input', currentValue);
      return true;
    },
    refresh() {
      let $this = this;
      let filter = {...this.modal.filter};
      if (this.isOwner) {
        filter.clientExt = {};
        filter.clientExt.owner = true;
      }
      this.getClients({
        sortby: $this.sort.order,
        sortcolumn: $this.sort.col,
        page: $this.pagination.number,
        amountPerPage: $this.pagination.amountPerPage,
        filter
      }).then(this.onClientsReceived);
    },
    onClientsReceived(data) {
      this.modal.clients = data['clients'];
      this.pagination = data['page']
      this.$root.$emit('bv::refresh::table')
    },
    toggleModal(status) {
      this.modal.active = status != null ? status : !this.modal.active;
    },
    onRowClicked(client) {
      if (this.multiple !== true) {
        this.$emit('input', client.id);
        this.modal.active = false;
        return
      }
      if(this.value == null){
        this.$emit('input', [client.id]);
        return;
      }
      if (this.removeClient(client.id) === true) {
        return;
      }
      this.$emit('input', [...this.value, client.id]);
    }
  }
}
</script>

<style lang="scss">
.client-picker {
  .input-group > .client-picker-unique.input-group-append {
    > .btn.client-picker-clear {
      padding-right: 4px;
      padding-left: 4px;
      border: none !important;
    }

    > .btn.client-picker-add {
      border: none !important;
    }

  }
}
</style>
